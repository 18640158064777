import React from "react";
const Dashboard = React.lazy(() => import("./components/dashboard/Dashboard"));
const Header = React.lazy(() => import("./components/Header"));
function App() {
  return (
    <div className="App">
      <Header />
      <Dashboard />
    </div>
  );
}

export default App;
